import { useEffect } from 'react';
import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import Head from 'next/head';

import type { ITag } from 'entities/tags';
import type { ICategory, IHub } from '../../entities/categories/model/ICategory';
import type { IFilter, IFilterFront, IFilterMeta } from '../../entities/filters/model/IFilter';
import type { IGoodCard } from 'entities/products';
import { ISticker } from 'src/models/ISticker';

import { useDispatch } from 'shared/lib/hooks/useDispatch';
import { useSelector } from 'shared/lib/hooks/useSelector';
import { getCategoryProps } from 'entities/categories';
import { useCategorySeo } from 'features/categories';
import {markTagsAsInvalid} from 'src/store/tags';
import {setCurrentFilters, markFiltersAsInvalid} from 'src/store/filters';

import { Breadcrumbs } from 'app/shared';
import { GoodsBlock } from '../../templates/GoodsBlock';
import { CategoryHeader } from 'widgets/categories';
import { HubBlock } from 'components/Hub/HubBlock';

import styles from 'global/styles/Home.module.scss';

export interface ICategoryProps {
  hasInvalidFilters: boolean;
  hasInvalidTags: boolean;
  category: ICategory;
  /** Доступные фильтры */
  filters: IFilter[];
  tags: ITag[];
  /** Примененные фильтры */
  currentFilters: Record<number, IFilterFront> | null;
  filtersMeta?: IFilterMeta;
  products?: IGoodCard[];
  meta?: {
    list: {
      count: number;
      pages_count: number;
    };
    stickers?: ISticker[];
  };
  hub?: IHub;
  breadcrumbs?: { title: string; href: string; clickableLast?: boolean }[];
}

export default function Catalog({
  hasInvalidFilters,
  hasInvalidTags,
  category,
  filters,
  tags,
  currentFilters,
  filtersMeta,
  products,
  meta,
  hub,
  breadcrumbs,
}: ICategoryProps) {
  const city = useSelector((state) => state.auth.city);
  const prevCity = Cookies.get('prevCity');
  const savedCity = Cookies.get('city') || '';
  const dispatch = useDispatch();
  const router = useRouter();

  const isExpress = router.asPath.includes('/ekspress-dostavka');
  const isFastDelivery = router.asPath.includes('/fast-delivery');
  const currentTags = useSelector((state) => state.tags.currentTags);
  const currentTag = useSelector((state) => state.tags.currentTags[state.tags.currentTags?.length - 1] || null);
  const currentTagWithArticle = currentTags.find((item) => Object.hasOwn(item, 'article'));

  // Значения для SEO-метатегов
  const { title, keywords, description, canonical, robots } = useCategorySeo(
    category,
    currentTags,
    filtersMeta,
    breadcrumbs
  );

  useEffect(() => {
    if (hasInvalidTags) dispatch(markTagsAsInvalid());
  }, [tags]);

  useEffect(() => {
    if (prevCity && (city || savedCity) && prevCity !== (city || savedCity)) {
      Cookies.set('prevCity', city || savedCity);
    }
  }, []);

  useEffect(() => {
    if (!prevCity && (city || savedCity)) Cookies.set('prevCity', city || savedCity);
    if (prevCity && city && prevCity !== city && currentFilters) {
      Cookies.set('prevCity', city);
      router.reload();
    }
  }, [city]);

  useEffect(() => {
    if (hasInvalidFilters) dispatch(markFiltersAsInvalid());
  }, [filters]);

  useEffect(() => {
    dispatch(setCurrentFilters(currentFilters));
  }, [currentFilters]);

  return (
    <>
      <Head>
        {hub ? (
          <>
            {hub?.seo_title && <title>{hub?.seo_title}</title>}
            {hub?.seo_keywords && <meta name="keywords" content={hub?.seo_keywords} />}
            {hub?.seo_description && <meta name="description" content={hub?.seo_description} />}
          </>
        ) : (
          <>
            {title && <title>{title}</title>}
            {keywords && <meta name="keywords" content={keywords} />}
            {description && <meta name="description" content={description} />}
            <link rel="canonical" href={canonical} />
            <meta name="robots" content={robots} />
          </>
        )}
      </Head>
      <div className={styles.page}>
        {!hub && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        <section>
          <div className="container container--for-cards">
            {hub ? (
              <>
                <h1>{hub?.page_title || hub.category.name}</h1>
                <HubBlock hub={hub} />
              </>
            ) : (
              <>
                <CategoryHeader
                  category={category}
                  currentTag={currentTag}
                  filtersTitle={filtersMeta?.h1}
                />
                <GoodsBlock
                  withFilters={Boolean(filters)}
                  categorySlug={category?.slug}
                  isExpress={isExpress}
                  isFastDelivery={isFastDelivery}
                  goods={products}
                  meta={meta}
                />
              </>
            )}
            {currentTagWithArticle && <article className="seoText" dangerouslySetInnerHTML={{__html: currentTagWithArticle.article}} />}
          </div>
        </section>
      </div>
    </>
  );
}

export const getServerSideProps: GetServerSideProps<ICategoryProps> = getCategoryProps;
