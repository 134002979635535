import { IHubsLink } from 'entities/categories/model/ICategory';
import Link from 'next/link';
import {memo} from 'react';

import styles from './../styles.module.scss';
import MenuIcon from 'templates/Header/ui/Icons/MenuIcon';

type IProps = {
  linkList: IHubsLink[];
};

export const HubsMenu = memo<IProps>(({ linkList }) => {
  const renderLinkChild = (link: IHubsLink) => {
    return (
      <div key={link.name} className={styles.linkItem}>
        <Link href={link.url}>
          <p>{link.name}</p>
        </Link>
      </div>
    );
  };

  return (
    <>
      {linkList.map((link: IHubsLink) => (
        <div className={styles.hubCol}>
          <div key={link.name} className={styles.linkBlock}>
            <div className={styles.linkTitleBlock}>
              <Link href={link.url} className={styles.linkTitle}>
                {link.name}
              </Link>
              <MenuIcon name="arrow" />
            </div>
            {link?.links?.length && link?.links?.map((linkChild) => renderLinkChild(linkChild))}
          </div>
        </div>
      ))}
    </>
  );
});
