import type { IHub } from 'entities/categories/model/ICategory';
import { HubsMenu } from '../HubMenu';

import styles from './../styles.module.scss';

export interface IProps {
  hub: IHub;
}

export const HubBlock = ({ hub }: IProps) => {
  return (
    <div className={styles.hubMenuBody}>
      <HubsMenu linkList={hub.links} />
    </div>
  );
};
